<template>
  <div id="select-wrap" class="mb-6">
    <Multiselect
      v-model="localSupport"
      :options="localSupports"
      :loading="isLoading"
      :disabled="loading"
      :close-on-select="true"
      :hide-selected="false"
      :allow-empty="false"
      :internal-search="false"
      label="label"
      track-by="id"
      :placeholder="$t('placeholder.support')"
      select-label=""
      selected-label=""
      deselect-label=""
      @input="changeSupport($event)"
      @search-change="findManager($event)"
      @close="isTouched = true"
      :class="{ invalid: isInvalid }"
    >
      <template slot="singleLabel" slot-scope="props">
        {{ props.option.label }}
        [{{ textUpperCase(`${props.option.id}`) }}]
      </template>
      <template slot="option" slot-scope="props">
        <span class="option__text ml-2">
          {{ props.option.label }}
        </span>
        <br />
        <span class="option__label ml-2 d-flex align-center">
          <v-icon color="privat">mdi-check</v-icon>
          [{{ textUpperCase(`${props.option.id}`) }}]
        </span>
      </template>
      <template slot="placeholder">{{ $t(`label.${label}`) }}</template>
    </Multiselect>
    <span class="single__legend" :class="{ hide: !localSupport }">
      {{ $t(`label.${label}`) }}
    </span>
    <span class="typo__label error--text" v-show="isInvalid">
      {{ $t("rules.required") }}
    </span>
  </div>
</template>

<script>
import { searchManager } from "@/services/settings.services";
import errorHandler from "@/utils/errorHandler";
import Multiselect from "vue-multiselect";
import changeTextMultiselect from "@/utils/changeTextMultiselect";
import { mapGetters } from "vuex";
import createMockData from "@/mock/mockNewData";

export default {
  name: "BaseSelectSupport",
  props: {
    supports: Array,
    label: String,
    loading: Boolean,
    validate: Boolean
  },
  data() {
    return {
      localSupport: null,
      localSupports: [],
      search: null,
      isLoading: false,
      isTouched: false
    };
  },
  components: {
    Multiselect
  },
  computed: {
    ...mapGetters(["devEnv"]),
    isInvalid() {
      return this.isTouched && !this.localSupport;
    }
  },
  methods: {
    findManager(val) {
      if (this.isLoading) return;

      if (val?.length >= 5) {
        this.searchSupport(val);
      }
    },
    searchSupport(val) {
      if (this.devEnv) {
        const data = createMockData("manager");

        this.localSupports = data.map(el => {
          return {
            ...el,
            label: `${el.surname} ${el.firstName} ${el.patronymic}`
          };
        });
        // mock
        return;
      }

      this.isLoading = true;
      searchManager(val)
        .then(res => {
          this.localSupports = [];
          this.localSupports = res.data.map(el => {
            return {
              ...el,
              label: `${el.surname} ${el.firstName} ${el.patronymic}`
            };
          });
          // this.localSupport = null;
        })
        .catch(err => {
          let errorCode = errorHandler(err, "getData");

          this.$emit("error", [this.$i18n.t(`error.${errorCode}`)]);
          console.log("catch searchManager");
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    changeSupport(val) {
      this.$emit("setSupport", val);
    },
    textUpperCase(text) {
      return text.toUpperCase();
    }
  },
  watch: {
    supports(val) {
      if (val.length === 1) {
        this.localSupport = val[0];
      }
    },
    validate() {
      this.isTouched = true;
    }
  },
  mounted() {
    this.timeoutInitMultiSeelect = setTimeout(() => {
      this.localSupports = this.supports;
      this.localSupport = this.supports[0] ?? null;
      changeTextMultiselect.call(this);
    }, 100);
  },
  beforeDestroy() {
    clearTimeout(this.timeoutInitMultiSeelect);
  }
};
</script>

<style scoped lang="scss">
#select-wrap {
  position: relative;
  ol,
  ul {
    padding-left: 0;
  }
}
</style>
